import { OfferConstants } from 'APP/constants/OfferConstants';
import { pbPartnerName } from 'APP/constants/pbPartnerName';
import { isBefore } from 'APP/helpers/DateTimeHelpers';

import { isOfferFromPartner } from './IsOfferFromPartner';

export const OfferHelpers = {
    /**
     * Returns true if the offer is a business offer.
     * @param {object} offer
     */
    isBusinessOffer(offer) {
        return offer.type === OfferConstants.TYPES.BUSINESS;
    },

    /**
     * Returns true if we have co complement enabled
     * @param {Object} offer
     * @returns {boolean}
     */
    hasCoApplicantComplement(offer) {
        const settings = offer.product.settings;
        return settings.some(setting => setting.id === 'co_applicant' && setting.enabled && setting.value);
    },

    /**
     * Returns an array with product requirements
     * @param {Object} offer
     * @returns {Array<string>}
     */
    getProductRequirements(offer) {
        const settings = offer.product.settings;
        const productRequirements = settings.find(setting => setting.id === 'product_requirements' && setting.enabled);
        if (!productRequirements) {
            return [];
        }

        return productRequirements.value;
    },

    /**
     * Returns an array with signing methods
     * @param {Object} offer
     * @returns {Array<string>}
     */
    getSigningMethods(offer) {
        const settings = offer.product.settings;
        const signingMethods = settings.find(setting => setting.id === 'signing_methods' && setting.enabled);
        if (!signingMethods) {
            return [];
        }

        return signingMethods.value;
    },

    /**
     * Returns wether a Bisnode credit check will have to be done.
     * @param {Object} offer
     * @returns {boolean}
     */
    hasBisnode(offer) {
        const settings = offer.product.settings;
        const bisnode = settings.find(setting => setting.id === 'bisnode' && setting.enabled);
        if (!bisnode) {
            return false;
        }

        return bisnode.value;
    },

    /**
     * Returns wether a co applicant is demanded.
     * @param {Object} offer
     * @returns {boolean}
     */
    hasGuarantorDemand(offer) {
        const settings = offer.product.settings;
        const guarantorDemand = settings.find(setting => setting.id === 'guarantor' && setting.enabled);
        if (!guarantorDemand) {
            return false;
        }

        return guarantorDemand.value;
    },

    /**
     * Returns wether an income verification is needed.
     * @param {Object} offer
     * @returns {boolean}
     */
    hasIncomeVerificationDemand(offer) {
        const settings = offer.product.settings;
        const incomeVerificationDemand = settings.find(
            setting => setting.id === 'income_verification' && setting.enabled
        );
        if (!incomeVerificationDemand) {
            return false;
        }

        return incomeVerificationDemand.value;
    },

    /**
     * Returns the safety insurance if one exists.
     * @param {Object} offer
     */
    getSafetyInsurance(offer) {
        return offer.upsells.find(upsell => upsell.type === OfferConstants.TYPES.SAFETY_INSURANCE);
    },

    /**
     * Returns the accident insurance if one exists.
     * @param {Object} offer
     */
    getAccidentInsurance(offer) {
        return offer.upsells.find(upsell => upsell.type === OfferConstants.TYPES.ACCIDENT_INSURANCE);
    },

    /**
     * Returns the loan insurance if one exists.
     * @param {Object} offer
     */
    getLoanInsurance(offer) {
        return offer.upsells.find(upsell => upsell.type === OfferConstants.TYPES.LOAN_INSURANCE);
    },

    /**
     * Returns the card offer if one exists.
     * @param {Object} offer
     */
    getCardOffer(offer) {
        return offer.upsells.find(upsell => upsell.type === OfferConstants.TYPES.CARD_OFFER);
    },

    /**
     * Returns sorted list of offers by highest offered amount.
     * @param {Array<object>} offers
     */
    sortByBusinessHighestAmount(offers) {
        return [...offers].sort((a, b) => {
            if (a.amount < b.amount) {
                return 1;
            } else if (a.amount > b.amount) {
                return -1;
            } else if (a.total_cost - a.amount > b.total_cost - b.amount) {
                return 1;
            } else if (a.total_cost - a.amount < b.total_cost - b.amount) {
                return -1;
            } else {
                return 0;
            }
        });
    },

    /**
     * Returns sorted list of offers by highest offered amount.
     * @param {Array<object>} offers
     */
    sortByPrivateHighestAmount(offers) {
        return [...offers].sort((a, b) => {
            if (a.amount < b.amount) {
                return 1;
            } else if (a.amount > b.amount) {
                return -1;
            } else if (a.interest_rate_effective > b.interest_rate_effective) {
                return 1;
            } else if (a.interest_rate_effective < b.interest_rate_effective) {
                return -1;
            } else {
                return 0;
            }
        });
    },

    /**
     * Returns sorted list of offers by highest chance to payout.
     * @param {Array<object>} offers
     */
    sortBySmartSorting(offers) {
        return offers;
    },

    /**
     * Returns sorted list of offers by lowest effective interest rate.
     * @param {Array<object>} offers
     */
    sortByLowestEffectiveInterestRate(offers) {
        return [...offers].sort((a, b) => {
            if (a.interest_rate_effective > b.interest_rate_effective) {
                return 1;
            } else if (a.interest_rate_effective < b.interest_rate_effective) {
                return -1;
            } else if (a.amount < b.amount) {
                return 1;
            } else if (a.amount > b.amount) {
                return -1;
            } else {
                return 0;
            }
        });
    },

    sortByLowestBusinessMonthlyCost(offers) {
        return [...offers].sort((a, b) => {
            if (Math.round(a.total_cost / a.amortize_length) > Math.round(b.total_cost / b.amortize_length)) {
                return 1;
            } else if (Math.round(a.total_cost / a.amortize_length) < Math.round(b.total_cost / b.amortize_length)) {
                return -1;
            } else {
                return 0;
            }
        });
    },

    /**
     * Returns sorted list of offers by lowest montly cost average.
     * @param {Array<object>} offers
     */
    sortByLowestMonthlyCostAverage(offers) {
        return [...offers].sort((a, b) => {
            if (a.monthly_cost_average > b.monthly_cost_average) {
                return 1;
            } else if (a.monthly_cost_average < b.monthly_cost_average) {
                return -1;
            } else if (a.amount < b.amount) {
                return 1;
            } else if (a.amount > b.amount) {
                return -1;
            } else {
                return 0;
            }
        });
    },

    /**
     * Returns sorted list of offers by lowest total cost.
     * @param {Array<object>} offers
     */
    sortByLowestBusinessTotalCost(offers) {
        return [...offers].sort((a, b) => a.total_cost - b.total_cost);
    },

    getMonthlyCost(offer) {
        if (offer.repayment_type === OfferConstants.REPAYMENT_TYPES.ANNUITY) {
            return Math.round(offer.monthly_cost_first);
        }

        return Math.round(offer.monthly_cost_first);
    },

    getMinimumMonthlyCost(offer) {
        return Math.round(offer.amount / offer.amortize_length);
    },

    getAllowedAccountTypes(offer) {
        const settings = offer.product.settings;
        const allowedAccountTypes = settings.find(setting => setting.id === 'allowed_account_types' && setting.enabled);
        // If none is set, allow all of them.
        if (!allowedAccountTypes || !Array.isArray(allowedAccountTypes.value) || allowedAccountTypes.value.length < 1) {
            return [
                OfferConstants.ALLOWED_ACCOUNT_TYPES.BANK_ACCOUNT,
                OfferConstants.ALLOWED_ACCOUNT_TYPES.BG,
                OfferConstants.ALLOWED_ACCOUNT_TYPES.PG,
            ];
        }

        return allowedAccountTypes.value;
    },

    /**
     * Checks if the given offer is expired.
     * @param {Object} offer
     */
    isExpired(offer) {
        return isBefore(offer.expires_at);
    },

    /**
     * find last withdrawn offer in the offers
     * @param {Object} withdrawnOffers
     */
    findLastWithdrawnOffer(withdrawnOffers) {
        const latestDate = new Date(Math.max(...withdrawnOffers.map(offer => new Date(offer.acceptance_withdrawn_at))));

        return withdrawnOffers.find(
            offer => new Date(offer.acceptance_withdrawn_at).getTime() === latestDate.getTime()
        );
    },

    /**
     * Checks if all offers are expired.
     * @param {Object} offer
     * @returns {boolean}
     */
    isAllOffersExpired(offers) {
        return offers?.every(offer => this.isExpired(offer));
    },

    /**
     * Checks if the given offer has been signed by an external partner.
     * @param {Object} offer
     */
    isOfferSignedByApplicant(offer, ssn) {
        const applicantSigningStatusInfo = offer.external_info?.applicant_signing_status;

        if (applicantSigningStatusInfo?.length > 0) {
            const applicant = applicantSigningStatusInfo.find(status => status.applicant_ssn === ssn);

            return applicant && applicant.signed_at !== null;
        } else {
            return false;
        }
    },

    /**
     * Returns a function that checks if a upsell exists for the given type.
     * @param {typeof OfferConstants.TYPES.SAFETY_INSURANCE | typeof OfferConstants.TYPES.LOAN_INSURANCE} type
     */
    findCheckedUpsell(type, upsells) {
        return function execute(upsell) {
            return upsell.type === type && upsells.length > 0 && upsell.status === 'active';
        };
    },

    checkPartnerAffiliation(offer) {
        const isCollectorCreditCard = offer.product.internal_name === 'collector_creditcard';
        const isBanky = offer.product.internal_name === 'banky_privateloan';
        const isCoop = isOfferFromPartner(offer, pbPartnerName.coop);
        const isBrixo = isOfferFromPartner(offer, pbPartnerName.brixo);
        const isIkano = isOfferFromPartner(offer, pbPartnerName.ikano);
        const isSevenday = isOfferFromPartner(offer, pbPartnerName.sevenDay);
        const isGoodCash = isOfferFromPartner(offer, pbPartnerName.goodCash);
        const isAvida = isOfferFromPartner(offer, pbPartnerName.avida);
        const isMarginalen = isOfferFromPartner(offer, pbPartnerName.marginalen);
        const isSaldo = isOfferFromPartner(offer, pbPartnerName.saldo);
        const isLoanStep = isOfferFromPartner(offer, pbPartnerName.loanstep);

        return {
            isBanky,
            isCoop,
            isBrixo,
            isIkano,
            isSevenday,
            isGoodCash,
            isAvida,
            isMarginalen,
            isCollectorCreditCard,
            isSaldo,
            isLoanStep,
        };
    },

    /**
     * Returns an array of denials that have a max loan amount and interest rates.
     */
    filterDenialsWithMaxLoanAmountAndInterestRates(denials) {
        if (denials?.length) {
            return denials.filter(denial => denial?.max_loan_amount && denial?.interests?.length > 0);
        }

        return [];
    },
};
