import { LoadingBounce } from '@lendoab/aphrodite';
import * as Sentry from '@sentry/react';
import { SeverityLevel } from '@sentry/types';
import { Analytics } from 'APP/Analytics';
import { createEffect } from 'APP/helpers/effect';
import Router from 'next/router';
import React, {
    createContext,
    Dispatch,
    PropsWithChildren,
    ReactElement,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useReducer,
    useRef,
} from 'react';

import { ApplicationActions } from './actions/ApplicationActions';
import { AuthActions } from './actions/AuthActions';
import { useAppSelector } from './feature/configStore';
import { IApplication } from './interfaces/applications.interfaces';
import {
    IAuthContextType,
    IAuthProvider,
    IEvent,
    IState,
    IUseAuthenticationProps,
} from './interfaces/authentication.interfaces';

const URL_AUTH_TOKEN_PATHNAME_PREFIX = '/v/';

const AuthContext = createContext<IAuthContextType | undefined>(undefined);

function getAuthTokenFromURLPathname(pathname: string): string | undefined {
    if (!pathname.startsWith(URL_AUTH_TOKEN_PATHNAME_PREFIX)) {
        return undefined;
    }

    return pathname.substring(URL_AUTH_TOKEN_PATHNAME_PREFIX.length);
}

function reducer(state: IState, event: IEvent): IState {
    switch (state.status) {
        case 'pending_url_token':
            if (event.type === 'RESOLVE_URL_TOKEN') {
                return {
                    ...state,
                    effects: [
                        ...state.effects,
                        createEffect('onAuthenticated', { authData: event.authData }),
                        createEffect('startSignOutTimer', { authData: event.authData }),
                        createEffect('trackUserInfo', { id: event.authData?.user.customer_id }),
                        createEffect('trackLogin', { method: 'token' }),
                        createEffect('setUserInSentry', { id: event.authData?.user.customer_id }),
                    ],
                    status: 'authenticated',
                };
            }

            if (event.type === 'REJECT_URL_TOKEN') {
                return {
                    ...state,
                    effects: [...state.effects, createEffect('validate')],
                    status: 'pending_validate',
                };
            }

            return state;
        case 'pending_validate':
            if (event.type === 'RESOLVE_VALIDATE') {
                return {
                    ...state,
                    effects: [
                        ...state.effects,
                        createEffect('onAuthenticated', { authData: event.authData }),
                        createEffect('startSignOutTimer', { authData: event.authData }),

                        createEffect('setUserInSentry', { id: event.authData?.user.customer_id }),
                    ],
                    status: 'authenticated',
                    authData: event.authData,
                };
            }

            if (event.type === 'REJECT_VALIDATE') {
                return {
                    ...state,
                    status: 'idle',
                };
            }

            return state;
        case 'idle':
        case 'rejected':
            if (event.type === 'BANKID_AUTH') {
                return {
                    ...state,
                    progress: undefined,
                    reference: undefined,
                    effects: [
                        ...state.effects,
                        createEffect('onAuthenticated', { authData: event.authData }),
                        createEffect('startSignOutTimer', { authData: event.authData }),
                        createEffect('trackUserInfo', {
                            id: event.authData?.user.customer_id,
                        }),
                        createEffect('trackLogin', {
                            method: 'bankId',
                        }),
                        createEffect('setUserInSentry', { id: event.authData?.user.customer_id }),
                    ],
                    status: 'authenticated',
                    authData: event.authData,
                };
            }

            return state;

        case 'authenticated':
            if (event.type === 'UN_AUTHENTICATE') {
                return {
                    ...state,
                    effects: [
                        ...state.effects,
                        createEffect('onUnAuthenticated'),
                        createEffect('invalidateJWT'),
                        createEffect('stopSignOutTimer'),
                    ],
                    status: 'idle',
                };
            }

            if (event.type === 'REFRESH') {
                return {
                    ...state,
                    effects: [...state.effects, createEffect('refresh')],
                };
            }

            if (event.type === 'RESOLVE_REFRESH') {
                return {
                    ...state,
                    authData: event.authData,
                    effects: [
                        ...state.effects,
                        createEffect('stopSignOutTimer'),
                        createEffect('startSignOutTimer', { authData: event.authData }),
                    ],
                };
            }

            return state;
        default:
            return state;
    }
}

export interface AuthData {
    token: '';
    exp: 0;
    permissions: [];
    user: {
        id: '';
        email: '';
        username: '';
        customer_id: '';
    };
    met: {};
}

function useAuthentication(props: IUseAuthenticationProps): [Omit<IState, 'effects'>, Dispatch<IEvent>] {
    const { onAuthenticated, onUnAuthenticated } = props;
    const { allApplications } = useAppSelector(state => state.applicationsSlice);
    const isInboxEventSent = useRef(false);

    useEffect(() => {
        if (!isInboxEventSent.current && !!allApplications.length) {
            allApplications.forEach((a: IApplication) => {
                ApplicationActions.inboxViewedWithApplicationEvent({
                    application_id: a.id,
                    revision: a.revision,
                }).catch(error => Sentry.captureException(error));
            });
            isInboxEventSent.current = true;
        }
    }, [allApplications]);

    const signOutTimer = useRef<number | undefined>(undefined);
    const initialState: IState = {
        status: 'pending_url_token',
        effects: [createEffect('urlToken')],
        authData: {
            token: '',
            exp: 0,
            permissions: [],
            user: {
                id: '',
                email: '',
                username: '',
                customer_id: '',
            },
            met: {},
        },
    };

    const [rawState, dispatch] = useReducer(reducer, initialState);
    const { effects, ...state } = rawState;

    useEffect(() => {
        if (effects.length < 1) {
            return;
        }

        for (const effect of effects) {
            if (effect.status !== 'idle') continue;
            effect.markAsStarted();
            if (effect.type === 'onAuthenticated' && effect.authData) {
                onAuthenticated(effect.authData);
            } else if (effect.type === 'onUnAuthenticated') {
                isInboxEventSent.current = false;
                onUnAuthenticated();
            } else if (effect.type === 'invalidateJWT') {
                AuthActions.close()
                    .then(res => {
                        if (res && res.headers) {
                            Sentry.addBreadcrumb({
                                message: `Invalidate JWT. Correlation ID: ${res.headers.get('x-correlation-id')}`,
                                level: 'error' as SeverityLevel,
                            });
                        }
                    })
                    .catch(error => {
                        Sentry.captureException(error);
                    });
            } else if (effect.type === 'urlToken') {
                const urlToken = getAuthTokenFromURLPathname(window.location.pathname);
                if (!urlToken) {
                    dispatch({ type: 'REJECT_URL_TOKEN' });
                } else if (urlToken === 'auto-login') {
                    // To reach this state the JWT token is stored in a cookie set by the backend.
                    // So we can assume that a JWT token is already present.
                    AuthActions.validateJWT()
                        .then(res => {
                            dispatch({ type: 'RESOLVE_URL_TOKEN', authData: res.auth });
                        })
                        .catch(() => {
                            dispatch({ type: 'REJECT_URL_TOKEN' });
                        });
                } else {
                    AuthActions.urlTokenLogin(urlToken)
                        .then(res => {
                            dispatch({ type: 'RESOLVE_URL_TOKEN', authData: res.auth });
                        })
                        .catch(() => {
                            dispatch({ type: 'REJECT_URL_TOKEN' });
                        });
                }
            } else if (effect.type === 'validate') {
                AuthActions.validateJWT()
                    .then(res => {
                        if (res && res.headers) {
                            Sentry.addBreadcrumb({
                                message: `Validate JWT. Correlation ID: ${res.headers.get('x-correlation-id')}`,
                                level: 'info' as SeverityLevel,
                            });
                        }
                        dispatch({ type: 'RESOLVE_VALIDATE', authData: res.auth });
                    })
                    .catch(() => {
                        dispatch({ type: 'REJECT_VALIDATE' });
                    });
            } else if (effect.type === 'startSignOutTimer' && effect.authData) {
                const expireMS = effect.authData.exp * 1000;
                const currentMS = Date.now();
                const millisecondsUntilExpire = expireMS - currentMS;

                signOutTimer.current = setTimeout(() => {
                    dispatch({ type: 'UN_AUTHENTICATE' });
                }, millisecondsUntilExpire) as unknown as number;
            } else if (effect.type === 'stopSignOutTimer') {
                if (!signOutTimer.current) {
                    continue;
                }

                clearTimeout(signOutTimer.current);
                signOutTimer.current = undefined;
            } else if (effect.type === 'refresh') {
                AuthActions.refreshJWT()
                    .then(res => {
                        if (res && res.headers) {
                            Sentry.addBreadcrumb({
                                message: `Refresh JWT. Correlation ID: ${res.headers.get('x-correlation-id')}`,
                                level: 'info' as SeverityLevel,
                            });
                        }
                        dispatch({ type: 'RESOLVE_REFRESH', authData: res.auth });
                    })
                    .catch(error => {
                        Sentry.captureException(error);
                    });
            } else if (effect.type === 'trackLogin' && effect.method) {
                Analytics.pushUserLogin({
                    method: effect.method,
                });
            } else if (effect.type === 'setUserInSentry') {
                Sentry.setUser({ id: effect.id });
            }

            effect.markAsStarted();
        }
    }, [effects, onAuthenticated, onUnAuthenticated]);

    return [state, dispatch];
}

export function AuthProvider(props: IAuthProvider): ReactElement {
    const { onAuthenticated, onUnAuthenticated, children } = props;

    const [state, dispatch]: [Omit<IState, 'effects'>, Dispatch<IEvent>] = useAuthentication({
        onAuthenticated,
        onUnAuthenticated,
    });

    const bankIdAuth = useCallback(
        (authData: AuthData) => {
            dispatch({ type: 'BANKID_AUTH', authData });
        },
        [dispatch]
    );

    const unAuthenticate = useCallback(() => {
        dispatch({ type: 'UN_AUTHENTICATE' });
    }, [dispatch]);

    const refresh = useCallback(() => {
        dispatch({ type: 'REFRESH' });
    }, [dispatch]);

    const value = useMemo(
        () => ({
            state,
            unAuthenticate,
            refresh,
            bankIdAuth,
        }),
        [state, unAuthenticate, refresh, bankIdAuth]
    );

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth(): IAuthContextType {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
}

export function withAuth(Component: React.ComponentType<PropsWithChildren<unknown>>) {
    return function AuthWrapper(props: PropsWithChildren<unknown>): ReactElement | null {
        const { state } = useAuth();

        useEffect(() => {
            if (state.status === 'idle' || state.status === 'rejected') {
                Router.push('/');
            }
        }, [state]);

        if (state.status.includes('pending')) {
            return <LoadingBounce />;
        }

        if (state.status === 'authenticated') {
            return <Component {...props} />;
        }

        return null;
    };
}
