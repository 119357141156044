import { externalRoutes } from '@frontend/shared/utils';
import { Box, DownloadIcon, Modal, ModalContent, NavigationLink, PlusIcon, Stack, Text } from '@lendoab/aphrodite';
import Link from 'next/link';
import PropTypes from 'prop-types';
import React, { cloneElement, useState } from 'react';

import styles from './UserAgreementModal.module.scss';

export function UserAgreementModal(props) {
    const { open, onClose, children, agreementRef } = props;

    return (
        <Modal style={{ zIndex: 6 }} open={open} onClose={onClose}>
            <ModalContent
                borderRadius="small"
                boxShadow="medium"
                style={{ width: '736px', maxWidth: '90vw', maxHeight: '95vh' }}
                display="flex"
                flexDirection="column"
            >
                <Box paddingBottom="medium">
                    <Text
                        weight="bold"
                        marginBottom="medium"
                        color="dark"
                        size={['medium', 'medium', 'large']}
                        align="center"
                    >
                        Användaravtal
                    </Text>
                    {!!agreementRef && (
                        <NavigationLink
                            target="_blank"
                            variant="secondary"
                            href={agreementRef}
                            iconRight={<DownloadIcon size="small" />}
                        >
                            Ladda ner
                        </NavigationLink>
                    )}
                    <button className={styles.modalCloseButton} onClick={onClose}>
                        <PlusIcon color="blue" />
                    </button>
                </Box>
                <Box style={{ flex: '1', overflowY: 'auto' }}>{children}</Box>
            </ModalContent>
        </Modal>
    );
}

UserAgreementModal.propTypes = {
    open: PropTypes.bool.isRequired,
    agreementRef: PropTypes.string,
    onClose: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
};

export function PrivateUserAgreementModal({ children }) {
    const [open, setOpen] = useState(false);
    const agreementRef = `${externalRoutes.cdn}/pdf/lendo_terms_and_conditions.pdf`;

    function handleClick(evt) {
        evt.preventDefault();
        setOpen(true);
    }

    return (
        <>
            {cloneElement(children, { onClick: handleClick })}
            <UserAgreementModal open={open} onClose={() => setOpen(false)} agreementRef={agreementRef}>
                <Stack space="medium">
                    <Stack space="xs" marginTop="large">
                        <Text color="dark" size={['small', 'small', 'medium']} weight="bold">
                            Om Tjänsten
                        </Text>

                        <Text color="dark" size={['xxs', 'xxs', 'xs']}>
                            Lendo är en kreditförmedlare som tillhandahåller en jämförelsetjänst där du kan jämföra lån
                            och villkor hos de banker och kreditgivare som Lendo samarbetar med (“Tjänsten”). Genom en
                            ansökan kan du få erbjudanden om lån från flera banker och andra kreditgivare. På så sätt
                            kan du jämföra räntevillkor från flera aktörer och välja det alternativ som passar dig bäst.
                            Du kan använda dig av Tjänsten när du vill samla existerande lån och t.ex.
                            kreditkortsskulder eller när du önskar ta ett nytt lån. Fördelen med att använda Lendos
                            Tjänst istället för att jämföra direkt hos bankerna är att det då endast tas en
                            kreditupplysning istället för flera och din kreditvärdighet bevaras.
                        </Text>

                        <Stack space="xs">
                            <Text color="dark" size={['small', 'small', 'medium']} weight="bold">
                                Kreditupplysning och informationsinhämtning
                            </Text>
                            <Text size={['xxs', 'xxs', 'xs']}>
                                Genom att använda dig av vår Tjänst så godkänner du, samt din eventuella medsökande, att
                                Lendo beställer en kreditupplysning på dig/er hos Upplysningscentralen AB (UC). Du
                                ansvarar för att din medsökande har tagit del av och accepterar dessa villkor. Genom
                                Lendos tjänst registreras bara en kreditupplysning som våra samarbetspartners får ta del
                                av. Enligt lag så kommer UC skicka omfrågandekopior till dig så du vet vilken
                                information som har lämnats ut och till vilka. Notera att du kan få flera
                                omfrågandekopior hemskickade, men att det inte innebär att flera kreditupplysningar
                                registreras på dig.
                            </Text>
                            <Text size={['xxs', 'xxs', 'xs']}>
                                I vissa fall kan våra samarbetspartners inhämta ytterligare information via Dun &
                                Bradstreet (tidigare Bisnode) som tillägg till informationen från UC för att förbättra
                                kvaliteten i kreditbeslutet. Detta påverkar inte din kreditvärdighet hos UC.
                            </Text>
                            <Text size={['xxs', 'xxs', 'xs']}>
                                Genom att använda dig av vår Tjänst godkänner du även att vi under handläggningen av
                                ditt ärende inhämtar kreditbeslut från de kreditgivare som vi samarbetar med i syfte att
                                fullgöra kreditförmedlingen.
                            </Text>
                        </Stack>

                        <Stack space="xs">
                            <Text color="dark" size={['small', 'small', 'medium']} weight="bold">
                                Ansvar
                            </Text>
                            <Text size={['xxs', 'xxs', 'xs']}>
                                Lendo ansvarar inte i något fall för förlust eller skada, om inte förlusten eller skadan
                                är direkt och orsakad av Lendos försumlighet. Om ett anspråk skulle framställas från
                                tredje part är eventuella förluster eller skador som du orsakats ”direkta” om de måste
                                betalas. Lendo är inte heller ansvarig för förlust eller skada som beror på svensk eller
                                utländsk lag eller annan föreskrift, svensk eller utländsk myndighets åtgärd, krig,
                                strejk, blockad, naturkatastrof eller annan liknande omständighet (force majeure).
                            </Text>
                            <Text size={['xxs', 'xxs', 'xs']} component="div">
                                Lendo påtar sig inte något ansvar för någon form av förlust eller skada som hänför sig
                                till:
                                <ul className={styles.list}>
                                    <li>
                                        att tjänsterna ligger nere, om någon information eller funktionalitet inte
                                        fungerar som förväntat eller för fel eller skador orsakade av listade
                                        samarbetspartners,
                                    </li>
                                    <li>
                                        beslut, ekonomiska eller andra, som fattas av dig baserat på användningen av
                                        denna tjänst eller för tjänster eller varor som du väljer att köpa från tredje
                                        part eller Lendos samarbetspartners,
                                    </li>
                                    <li>
                                        skada eller förlust som inträffat på grund av ett ingånget avtal med någon
                                        Lendos samarbetspartners, eller
                                    </li>
                                    <li>andra omständigheter som är utanför Lendos kontroll.</li>
                                </ul>
                            </Text>
                        </Stack>

                        <Stack space="xs">
                            <Text color="dark" size={['small', 'small', 'medium']} weight="bold">
                                Om Lendo
                            </Text>

                            <Text color="dark" size={['xxs', 'xxs', 'xs']}>
                                Tjänsten tillhandahålls av Lendo AB (org.nr 556515-1627) som har med tillstånd att driva
                                Konsumentkreditinstitut av Finansinspektionen. Lendos tillstånd går att kontrollera på{' '}
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://finansinspektionen.se/sv/vara-register/foretagsregistret/details?id=72235"
                                >
                                    Finansinspektionens hemsida
                                </a>
                                .
                            </Text>
                            <Text color="dark" size={['xxs', 'xxs', 'xs']}>
                                Tjänsten tillhandahålls i samarbete med Lendos norska systerbolag Lendo Part of
                                Schibsted AS, (norskt org.nr 997 523 814) (“Lendo Norge”). Lendo Norge är
                                huvudförmedlare för Tjänsten och driver Lendos tekniska förmedlingsplattform och Lendo
                                AB är underförmedlare. Lendo Norge har tillstånd som norskt betaltjänstleverantör och
                                står under tillsyn av norska Finansinspektionen (No. Finanstilsynet) med tillstånd att
                                erbjuda gränsöverskridande tjänster i Sverige. Lendo Norges tillstånd går att
                                kontrollera på{' '}
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://finansinspektionen.se/sv/vara-register/foretagsregistret/details?id=250902"
                                >
                                    Finansinspektionens hemsida
                                </a>
                                .
                            </Text>
                        </Stack>
                        <Stack space="xs">
                            <Text color="dark" size={['small', 'small', 'medium']} weight="bold">
                                Behandling av Personuppgifter
                            </Text>
                            <Text color="dark" size={['xxs', 'xxs', 'xs']}>
                                För Lendos behandling av personuppgifter, se{' '}
                                <Link href="https://www.lendo.se/privacy-policy/personuppgiftspolicy">
                                    Lendos Personuppgiftspolicy
                                </Link>
                            </Text>
                        </Stack>
                    </Stack>
                </Stack>
            </UserAgreementModal>
        </>
    );
}

PrivateUserAgreementModal.propTypes = {
    children: PropTypes.node.isRequired,
};
