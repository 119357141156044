export function getBrowserName(): string {
    let browserName = '';
    if (typeof window !== 'undefined') {
        const userAgent = navigator.userAgent;

        if (userAgent.match(/chrome|chromium|crios/i)) {
            browserName = 'Chrome';
        } else if (userAgent.match(/firefox|fxios/i)) {
            browserName = 'Firefox';
        } else if (userAgent.match(/safari/i)) {
            browserName = 'Safari';
        } else if (userAgent.match(/opr\//i)) {
            browserName = 'Opera';
        } else if (userAgent.match(/edg/i)) {
            browserName = 'Edge';
        } else {
            browserName = 'No browser detection';
        }
    }
    return browserName;
}

export function getOsName(): string {
    let name = '';
    if (typeof window !== 'undefined') {
        if (navigator.appVersion.indexOf('Win') !== -1) {
            name = 'Windows';
        } else if (navigator.appVersion.indexOf('Mac') !== -1) {
            name = 'Mac';
        } else if (navigator.appVersion.indexOf('X11') !== -1) {
            name = 'UNIX';
        } else if (navigator.appVersion.indexOf('Linux') !== -1) {
            name = 'Linux';
        } else {
            name = 'No os detected';
        }
    }
    return name;
}

export function getHostName(): string {
    let name = '';
    if (typeof window !== 'undefined') {
        if (window.location.hostname === 'www.lendo.se' || window.location.hostname === 'inbox.lendo.se') {
            name = 'Prod';
        } else if (
            window.location.hostname === 'stage.lendo.se' ||
            window.location.hostname === 'inbox.stage.lendo.se'
        ) {
            name = 'Stage';
        } else {
            name = 'localhost';
        }
    }
    return name;
}

export function getDeviceType(): string {
    if (typeof window !== 'undefined') {
        const userAgent = navigator.userAgent;
        if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(userAgent)) {
            return 'Tablet';
        }
        if (
            /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
                userAgent
            )
        ) {
            return 'Mobile';
        }
        return 'Desktop';
    }

    return 'No device detected';
}
