import { Analytics } from 'APP/Analytics';
import { hasSmartRevision } from 'APP/components/CustomerRevision/helpers/hasSmartRevision';
import { ApplicationConstants } from 'APP/constants/ApplicationConstants';
import { VariationConstants } from 'APP/constants/VariationConstants';

// Select Options helpers
export const amortizeLengthOptions = [
    { value: '12', label: '1 år' },
    { value: '24', label: '2 år' },
    { value: '36', label: '3 år' },
    { value: '48', label: '4 år' },
    { value: '60', label: '5 år' },
    { value: '72', label: '6 år' },
    { value: '84', label: '7 år' },
    { value: '96', label: '8 år' },
    { value: '108', label: '9 år' },
    { value: '120', label: '10 år' },
    { value: '132', label: '11 år' },
    { value: '144', label: '12 år' },
    { value: '156', label: '13 år' },
    { value: '168', label: '14 år' },
    { value: '180', label: '15 år' },
];

export const months = [
    { value: '1', label: 'Januari' },
    { value: '2', label: 'Februari' },
    { value: '3', label: 'Mars' },
    { value: '4', label: 'April' },
    { value: '5', label: 'Maj' },
    { value: '6', label: 'Juni' },
    { value: '7', label: 'Juli' },
    { value: '8', label: 'Augusti' },
    { value: '9', label: 'September' },
    { value: '10', label: 'Oktober' },
    { value: '11', label: 'November' },
    { value: '12', label: 'December' },
];

export const civilStateOptions = [
    { value: 'single', label: 'Ensamstående' },
    { value: 'live_together', label: 'Sambo' },
    { value: 'married', label: 'Gift' },
    { value: 'divorced', label: 'Skild' },
];

export const numberOfChildrenOptions = [
    { value: '0', label: 'Inga' },
    { value: '1', label: '1 barn' },
    { value: '2', label: '2 barn' },
    { value: '3', label: '3 barn' },
    { value: '4', label: '4 barn' },
    { value: '5', label: '5 eller mer' },
];

export const HousingOptionsEnums = {
    rental: 'rental',
    house: 'house',
    condominium: 'condominium',
    tenant: 'tenant',
};

export const accommodationOptions = [
    { value: HousingOptionsEnums.rental, label: 'Hyresrätt' },
    { value: HousingOptionsEnums.house, label: 'Villa eller radhus' },
    { value: HousingOptionsEnums.condominium, label: 'Bostadsrätt' },
    { value: HousingOptionsEnums.tenant, label: 'Inneboende' },
];

export function sinceYearOptions() {
    let options = [];
    const currentYear = new Date().getFullYear();

    for (let i = 0; i < 10; i++) {
        options.push({
            label: (currentYear - i).toString(),
            value: (currentYear - i).toString(),
        });
    }

    options.push({
        label: `Innan ${currentYear - 9}`,
        value: (currentYear - 10).toString(),
    });

    return options;
}

export function untilYearOptions() {
    const currentYear = new Date().getFullYear();
    let options = [];

    for (let i = 0; i < 10; i++) {
        options.push({
            label: (currentYear + i).toString(),
            value: (currentYear + i).toString(),
        });
    }

    options.push({
        label: `Efter ${currentYear + 9}`,
        value: (currentYear + 10).toString(),
    });

    return options;
}

// Label helpers
export const accommodationCostLabels = {
    mainApplicant: {
        rental: 'Din del av månadshyran',
        house: 'Din del av driftskostnaden per månad',
        condominium: 'Din del av månadsavgiften',
        tenant: 'Din månadshyra',
    },
    coApplicant: {
        rental: 'Medsökandes del av månadshyran',
        house: 'Medsökandes del av driftskostnaden per månad',
        condominium: 'Medsökandes del av månadsavgiften',
        tenant: 'Medsökandes månadshyra',
    },
};
export function getAccommodationCostLabel(applicantType, accommodationType) {
    return accommodationCostLabels[applicantType]?.[accommodationType] || 'Del av månadskostnaden';
}

// Tooltip helpers
export const accommodationCostTooltips = {
    coApplicant: {
        rental: {
            title: 'Månadshyra',
            text: 'Delar medsökande på kostnaden för hyresrätten med någon annan? Ange den del medsökande betalar i månaden.',
        },
        house: {
            title: 'Driftkostnad',
            text: 'Kringkostnader för medsökandes boende, exempelvis: hemförsäkring, el, vatten, värme, sophämtning och samfällighetsavgifter. Räkna inte med ränta och amortering på bolån. Ange den del medsökande betalar i månaden.',
        },
        condominium: {
            title: 'Månadsavgift',
            text: 'Delar medsökande på kostnaden för bostadsrätten med någon annan? Ange den del medsökande betalar i månaden.',
        },
        tenant: { title: 'Månadshyra', text: 'Ange den summa medsökande betalar i månaden till sin hyresvärd.' },
    },
    mainApplicant: {
        rental: {
            title: 'Månadshyra',
            text: 'Delar du på kostnaden för hyresrätten med någon annan? Ange den del du betalar i månaden.',
        },
        house: {
            title: 'Driftkostnad',
            text: 'Kringkostnader för ditt boende, exempelvis: hemförsäkring, el, vatten, värme, sophämtning och samfällighetsavgifter. Du ska inte räkna med ränta och amortering på bolån. Ange den del du betalar i månaden.',
        },
        condominium: {
            title: 'Månadsavgift',
            text: 'Delar du på kostnaden för bostadsrätten med någon annan? Ange den del du betalar i månaden.',
        },
        tenant: { title: 'Månadshyra', text: 'Ange den summa du betalar i månaden till din hyresvärd.' },
    },
};

export function getAccommodationCostTooltip(applicantType, accommodationType) {
    return accommodationCostTooltips[applicantType]?.[accommodationType];
}

export const showAccommodationWarning = income => {
    const incomeNumber = Number(income);
    return incomeNumber > 14999 && incomeNumber < 100001;
};

export function getSinceLabel(employmentType) {
    switch (employmentType) {
        case 'pension':
        case 'early_pension':
            return 'Pensionär sedan';
        case 'own_company':
            return 'Har haft eget företag sedan';
        case 'part_time':
        case 'hourly_employment':
        case 'finite_time':
        case 'full_time':
        default:
            return 'Anställd sedan';
    }
}

// Tracking helpers
export function pushFieldError(field, step, stepName, state) {
    Analytics.pushFormFieldError({
        formName: 'revisionForm',
        stepNumber: step,
        stepName: stepName,
        fieldName: field.name,
        fieldType: field.meta.fieldType,
        errorCode: 'todo',
        errorMessage: field.message,
        product: ApplicationConstants.TYPE.PRIVATE,
        ...(hasSmartRevision(state) && { variation: VariationConstants.SMART_CUSTOMER_REVISION }),
    });
}

export function pushFieldInteraction(field, step, stepName, state) {
    Analytics.pushFormFieldInteraction({
        fieldInteraction: 'change',
        ...(field.meta.trackValue && { fieldValue: field.value }),
        formName: 'revisionForm',
        stepNumber: step,
        stepName: stepName,
        fieldName: field.name,
        fieldType: field.meta.fieldType || field.meta.type,
        product: ApplicationConstants.TYPE.PRIVATE,
        ...(hasSmartRevision(state) && { variation: VariationConstants.SMART_CUSTOMER_REVISION }),
    });
}

export function pushNewLoanSolveRatioError(state) {
    Analytics.pushFormFieldError({
        formName: 'revisionForm',
        stepNumber: 1,
        stepName: 'solveLoanAmount',
        fieldName: 'solveLoanAmount',
        fieldType: 'input',
        errorMessage: 'Du kan inte samla en större summa än det totala lånet',
        fieldValue: '',
        product: ApplicationConstants.TYPE.PRIVATE,
        ...(hasSmartRevision(state) && { variation: VariationConstants.SMART_CUSTOMER_REVISION }),
    });
}

export function pushPreviousStepFieldInteraction(state, isEdit) {
    Analytics.pushFormFieldInteraction({
        formName: 'revisionForm',
        stepNumber: state.step,
        stepName: state.stepName,
        fieldName: isEdit ? 'edit' : 'backwards',
        fieldType: 'button',
        fieldInteraction: 'change',
        product: ApplicationConstants.TYPE.PRIVATE,
        ...(hasSmartRevision(state) && { variation: VariationConstants.SMART_CUSTOMER_REVISION }),
    });
}

export function pushFormStepSubmit(state, isSuccess) {
    Analytics.pushFormStepSubmit({
        formName: 'revisionForm',
        stepName: findPrevStepName(state.step, state.coApplicant),
        stepNumber: state.step,
        success: isSuccess,
        product: ApplicationConstants.TYPE.PRIVATE,
        ...(hasSmartRevision(state) && { variation: VariationConstants.SMART_CUSTOMER_REVISION }),
    });
}

export function pushFormStepImpression(state) {
    Analytics.pushFormStepImpression({
        formName: 'revisionForm',
        formStep: state.step,
        stepName: state.stepName,
        product: ApplicationConstants.TYPE.PRIVATE,
        ...(hasSmartRevision(state) && { variation: VariationConstants.SMART_CUSTOMER_REVISION }),
    });
}

export function pushPageView(windowLocationHostname, title) {
    Analytics.pushPageView({
        product: ApplicationConstants.TYPE.PRIVATE,
        location: windowLocationHostname,
        path: windowLocationHostname,
        title: title,
    });
}

export function pushRedirect(state) {
    Analytics.pushFormFieldInteraction({
        formName: 'revisionForm',
        stepNumber: state.step,
        stepName: state.stepName,
        fieldName: 'toInbox',
        fieldType: 'button',
        fieldInteraction: 'press',
        product: ApplicationConstants.TYPE.PRIVATE,
    });
}

export function pushRevisionSubmitted(state) {
    Analytics.pushRevisionSubmitted({
        applicationId: state.application.id,
        product: ApplicationConstants.TYPE.PRIVATE,
        amortizeLength: state.amortizeLength,
        amount: state.totalLoanAmount,
        amountToSolve: state.solveLoanAmount,
        hasAddedCoApplicant: !state.hasCoApplicantInitally && state.coApplicant,
        featureSmartRevision: hasSmartRevision(state),
    });
}
export function pushRevisionCreated(state) {
    Analytics.pushRevisionCreated({
        applicationId: state.application.id,
        product: ApplicationConstants.TYPE.PRIVATE,
        amortizeLength: state.amortizeLength,
        amount: state.totalLoanAmount,
        amountToSolve: state.solveLoanAmount,
        hasAddedCoApplicant: !state.hasCoApplicantInitally && state.coApplicant,
        featureSmartRevision: hasSmartRevision(state),
    });
}

export function pushRevisionRejected(state, error) {
    Analytics.pushRevisionRejected({
        applicationId: state.application.id,
        product: ApplicationConstants.TYPE.PRIVATE,
        amortizeLength: state.amortizeLength,
        amount: state.totalLoanAmount,
        amountToSolve: state.solveLoanAmount,
        errorCode: error.errorCode,
        errorMessage: error.errorMessage,
        hasAddedCoApplicant: !state.hasCoApplicantInitally && state.coApplicant,
        featureSmartRevision: hasSmartRevision(state),
    });
}

export function findPrevStepName(stepNumber, coApplicant) {
    switch (stepNumber) {
        case 1:
            return 'index';

        case 2:
            if (coApplicant) {
                return 'personalInformation';
            } else {
                return 'summary';
            }

        case 3:
            if (coApplicant) {
                return 'accommodation';
            } else {
                return 'confirmationPage';
            }

        case 4:
            return 'employment';
        case 5:
            return 'summary';

        default:
            return '';
    }
}
