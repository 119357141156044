import { ApplicationConstants, ApplicationStatus } from 'APP/constants/ApplicationConstants';
import { IApplication, IApplicationApplicant, LoanInsurance } from 'APP/interfaces/applications.interfaces';
import { BizApplication } from 'APP/interfaces/business/application';
import { BizOffer } from 'APP/interfaces/feature/business/offers.interfaces';
import { ICampaign } from 'APP/interfaces/helpers';
import { IOffer } from 'APP/interfaces/private/offers.interfaces';
import { Routes } from 'APP/Routes';

import { isBefore } from './DateTimeHelpers';
import { OfferHelpers } from './OfferHelpers';

export const ApplicationHelpers = {
    getApplication(applications: IApplication[], id: string): IApplication | undefined {
        return applications.find(application => application.id === id);
    },

    /**
     * Get all applications matching the given id.
     * @param {Array<object>} applications
     */
    getApplications(applications: IApplication[], id: string): IApplication[] | undefined {
        return applications.filter(application => application.id === id);
    },

    /**
     * Returns an object with the active application as well as the old revisions
     * @param {Array<Object>} applications
     * @param {string} id
     * @returns {{ application: Object, oldRevisions: Arrar<Object> }}
     */
    getRelevantApplications(
        applications: IApplication[],
        id: string
    ): { application: IApplication | null; oldRevisions?: IApplication[] } {
        const apps = ApplicationHelpers.getApplications(applications, id);
        const application = ApplicationHelpers.getMostRecentRevision(apps as IApplication[]);
        const oldRevisions = apps?.filter(app => app.revision !== application?.revision);
        return { application, oldRevisions };
    },

    /**
     * Returns an object with the active application as well as the old revisions
     * @param {Array<Object>} applications
     * @param {string} queryId
     * @param {string} offerId
     * @returns {application: Object}
     */
    getRelevantApplication(
        allApplications: IApplication[],
        queryId: string,
        offerId: string
    ): IApplication | undefined {
        return allApplications.find(app => app.id === queryId && app.offers.some(appOffer => appOffer.id === offerId));
    },

    /**
     * Filters out duplicates but keeps the latest revision of them.
     * @param {Array<object>} applications
     */
    filterDuplicateApplications(applications: IApplication[]): IApplication[] {
        if (!applications || !applications.length) {
            return [];
        }

        if (applications.length === 1) {
            return applications;
        }

        const newApplications = new Map();
        for (const application of applications) {
            if (newApplications.has(application.id)) {
                // We have a par of duplicates, let's grab the latest revision.
                newApplications.set(
                    application.id,
                    ApplicationHelpers.getMostRecentRevision([newApplications.get(application.id), application])
                );
            } else {
                newApplications.set(application.id, application);
            }
        }

        return Array.from(newApplications.values());
    },

    /**
     * Checks if mortgage application exists in the array of applications
     * @param {Array<object>} applications
     */
    hasMortgageApplication(applications: IApplication[]): IApplication | undefined | [] {
        if (!applications || !applications.length) {
            return [];
        }

        return applications.find(application => application.type === ApplicationConstants.TYPE.MORTGAGE);
    },

    excludeMortgageFromApplications(applications: IApplication[]): IApplication[] {
        if (!applications || !applications.length) {
            return [];
        }

        return applications.filter(application => application.type !== ApplicationConstants.TYPE.MORTGAGE);
    },

    /**
     * Filters out the most recent application based on the created_at date.
     * @param {Array<object>} applications
     */
    getLatestApplication(applications: IApplication[]): IApplication | null {
        function orderByCreatedAt(a: IApplication, b: IApplication): IApplication {
            return a.created_at > b.created_at ? a : b;
        }

        if (!applications || !applications.length) {
            return null;
        }

        return applications.reduce(orderByCreatedAt, applications[0]);
    },

    /**
     * Filters out the most recent application based on the revision.
     * @param {Array<object>} applications
     */
    getMostRecentRevision(applications: IApplication[]): IApplication | null {
        if (!applications || !applications.length) {
            return null;
        }

        if (applications.length === 1) {
            return applications[0];
        }

        return applications.reduce((a, b) => (a.revision > b.revision ? a : b));
    },

    /**
     * Check if offer is for the current revision or not
     * @param {Array<object>} applications
     * * @param {Ioffer| BizOffer} offer
     */
    isOfferOnCurrentRevision(applications: IApplication[], offer: IOffer | BizOffer): boolean {
        const mostRecentRevision = this.getMostRecentRevision(applications);
        return mostRecentRevision?.revision === offer?.revision;
    },

    getOffer(application: IApplication, id: string): IOffer | undefined {
        return application.offers.find(offer => offer.id === id);
    },

    /**
     * Returns true if the application is a private application
     * @param {object} application
     */
    isPrivateApplication(application: IApplication): boolean {
        return application.type === ApplicationConstants.TYPE.PRIVATE;
    },

    /**
     * Returns true if the application is a business application
     * @param {object} application
     */
    isBusinessApplication(application: BizApplication): boolean {
        return application.type === ApplicationConstants.TYPE.BUSINESS;
    },

    /**
     * Returns true if the application is a mortgage application
     * @param {object} application
     */
    isMortgageApplication(application: IApplication): boolean {
        return application?.type === ApplicationConstants.TYPE.MORTGAGE;
    },

    /**
     * Returns true if the application is a private business application.
     * @param {object} application
     */
    isPrivateBusinessApplication(application: IApplication): boolean {
        return application.private_business === true;
    },

    /**
     * Returns the main applicant
     */
    getMainApplicant(application: IApplication): IApplicationApplicant | undefined {
        return application.applicants.find(applicant => applicant.type === 'main');
    },

    hasCampaign(application: IApplication): boolean {
        return Array.isArray(application?.campaigns) && application.campaigns.length > 0;
    },

    /**
     * Checks if the given application id has multiple revisions.
     * @param {Array<Object>} applications
     * @param {string} id
     */
    doesMultipleRevisionsExist(applications: IApplication[], id: string): boolean {
        return applications.filter(app => app.id === id).length > 1;
    },

    /**
     * Attempts to find the revision that has an acceptance
     * @param {Array<Object>} applications
     * @param {string} id
     * @returns {null | Object}
     */
    findAcceptedRevision(applications: IApplication[], id: string): IApplication | null {
        const acceptedRevisions = applications.filter(app => app.id === id && app.acceptance);
        if (acceptedRevisions.length === 0) {
            return null;
        }
        // We should never have more than 1 acceptance, but if it happens grab the most recent one.
        // Ideally, most of this logic should be moved to the backend.
        return ApplicationHelpers.getMostRecentRevision(acceptedRevisions);
    },

    /**
     * Returns the campaign if one exists.
     * @param {*} application
     */
    getCampaign(application: IApplication): ICampaign | undefined {
        if (!ApplicationHelpers.hasCampaign(application)) {
            return undefined;
        }

        return application.campaigns[0];
    },

    /**
     * Returns the route to redirect to.
     * @param {*} revision
     */
    getAppropriateRoute(revision: IApplication): string {
        if (revision.type === ApplicationConstants.TYPE.MORTGAGE) {
            if (revision.acceptance) {
                return Routes.getAcceptanceRoute(revision.id);
            }
            return Routes.getMortgageApplicationRoute(revision.id);
        } else {
            if (revision.acceptance) {
                return Routes.getAcceptanceRoute(revision.id);
            }
            return Routes.getApplicationRoute(revision.id);
        }
    },

    getApplicationStatus(app: IApplication): string {
        if (app?.status === ApplicationConstants.STATUS.DENIED) {
            if (app.potential_business_customer) {
                return ApplicationStatus.POTENTIAL_BUSINESS_CUSTOMER;
            }
            return ApplicationStatus.DENIED;
        }

        if (
            (app?.offers?.length > 0 && app.status === ApplicationConstants.STATUS.APPROVED) ||
            app.status === ApplicationConstants.STATUS.PRODUCTS_RESPONDED
        ) {
            if (app.offers.every(OfferHelpers.isExpired)) {
                return ApplicationStatus.EXPIRED;
            }
            return ApplicationStatus.HAS_OFFERS;
        }

        if (isBefore(app?.responses_expired_at)) {
            return ApplicationStatus.EXPIRED;
        }

        return ApplicationStatus.NO_OFFERS;
    },

    calculateOfferMonthlyCost(offer: IOffer | BizOffer, loanInsurance: LoanInsurance): number {
        let monthlyCost = OfferHelpers.getMonthlyCost(offer);
        if (loanInsurance) {
            monthlyCost = monthlyCost + loanInsurance.monthly_cost;
        }
        return monthlyCost;
    },

    calculateTotalMonthlyCost(
        offer: IOffer | BizOffer,
        loanInsurance: { monthly_cost: number },
        safetyInsurance: { monthly_cost: number },
        accidentInsurance: { monthly_cost: number }
    ): number {
        let monthlyCost = OfferHelpers.getMonthlyCost(offer);
        if (loanInsurance) {
            monthlyCost = monthlyCost + loanInsurance.monthly_cost;
        }
        if (safetyInsurance) {
            monthlyCost = monthlyCost + safetyInsurance.monthly_cost;
        }
        if (accidentInsurance) {
            monthlyCost = monthlyCost + accidentInsurance.monthly_cost;
        }
        return monthlyCost;
    },

    hasComplements(offer: IOffer | BizOffer): boolean {
        return offer.complements.length > 0;
    },
    hasAmountToSolve(offer: IOffer | BizOffer): boolean {
        return Number(offer.amount_to_solve_external) > 0 || Number(offer.amount_to_solve_internal) > 0;
    },
};
