import { externalRoutes } from '@frontend/shared/utils';
import { Box, Button, ContentBlock, Inline, LogoutIcon, Portal, Text } from '@lendoab/aphrodite';
import { Analytics } from 'APP/Analytics';
import { useAuth } from 'APP/Authentication';
import DesktopCallButton from 'APP/components/CustomerSupport/DesktopCallButton';
import MobileCallButton from 'APP/components/CustomerSupport/MobileCallButton';
import { resetReduxStates } from 'APP/feature/general/generalSlice';
import { classNames } from 'APP/helpers/css';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { Fragment, ReactElement, useState } from 'react';
import { useDispatch } from 'react-redux';

import styles from './Navigation.module.scss';
import { Overlay } from './Overlay';
import { IMobileSubNavBarProps, SubNav } from './SubNav';

function onCallButtonClicked(): void {
    Analytics.pushUIInteraction({
        category: 'header',
        title: 'call',
        type: 'click',
    });
}

export function Navigation(): ReactElement | null {
    const { pathname } = useRouter();
    const [open, setOpen] = useState(false);
    const navClassNames = classNames(styles.nav, open && styles.open);
    const { state, unAuthenticate } = useAuth();
    const isAuthenticated = state.status === 'authenticated';
    const isLoginPage = pathname === '/';

    return !isLoginPage ? (
        <Fragment>
            <Box
                backgroundColor="green-gradient"
                fluid
                component="nav"
                display={['flex', 'flex', 'block']}
                justifyContent="center"
                alignItems="center"
                className={navClassNames}
            >
                <ContentBlock>
                    <MobileNavBar
                        open={open}
                        setOpen={setOpen}
                        isAuthenticated={isAuthenticated}
                        unAuthenticate={unAuthenticate}
                    />
                    <DesktopNavBar isAuthenticated={isAuthenticated} unAuthenticate={unAuthenticate} />
                </ContentBlock>
            </Box>
            <Portal>
                <Overlay open={open} onClick={(): void => setOpen(false)} />
                <SubNav open={open} setOpen={setOpen} isAuthenticated={isAuthenticated} />
            </Portal>
        </Fragment>
    ) : null;
}

interface IMobileNavBarProps extends IMobileSubNavBarProps {
    unAuthenticate: () => void;
}

function MobileNavBar(props: IMobileNavBarProps): ReactElement {
    const { setOpen, open, isAuthenticated, unAuthenticate } = props;
    const reduxDispatch = useDispatch();
    function onHamburgerClicked(): void {
        Analytics.pushUIInteraction({
            type: !open ? 'open' : 'close',
            category: 'sidemenu',
            title: !open ? 'Menu' : 'Close',
        });

        setOpen(!open);
    }

    return (
        <Box
            fluid
            display={['flex', 'none', 'none']}
            justifyContent="space-between"
            alignItems="center"
            paddingTop={['base', 'xs']}
            paddingBottom={['base', 'xs']}
            paddingX={['base', 'small']}
        >
            <NavLogo isAuthenticated={isAuthenticated} />

            {isAuthenticated && (
                <Button
                    onClick={(): void => {
                        unAuthenticate();
                        reduxDispatch(resetReduxStates());
                    }}
                    display={['none', 'flex']}
                    size="small"
                    marginLeft={['none', 'auto']}
                >
                    Logga ut
                </Button>
            )}

            <Box display="flex" alignItems="center">
                <MobileCallButton onClick={onCallButtonClicked} />
                {isAuthenticated && (
                    <Hamburger
                        onClick={onHamburgerClicked}
                        open={open}
                        marginLeft={isAuthenticated && ['medium', 'xl']}
                    />
                )}
            </Box>
        </Box>
    );
}

function DesktopNavBar(props: { isAuthenticated: boolean; unAuthenticate: () => void }): ReactElement {
    const { isAuthenticated, unAuthenticate } = props;
    const reduxDispatch = useDispatch();
    return (
        <Box
            fluid
            display={['none', 'flex', 'flex']}
            justifyContent="space-between"
            alignItems="center"
            paddingTop="xs"
            paddingBottom="xs"
        >
            <NavLogo isAuthenticated={isAuthenticated} />

            <Inline display="flex" space="medium" flexWrap="nowrap">
                <DesktopCallButton onClick={onCallButtonClicked} />
                {isAuthenticated && (
                    <Button
                        size="small"
                        endIcon={<LogoutIcon size="medium" />}
                        onClick={(): void => {
                            unAuthenticate();
                            reduxDispatch(resetReduxStates());
                        }}
                    >
                        Logga ut
                    </Button>
                )}
            </Inline>
        </Box>
    );
}

function NavLogo({ isAuthenticated }: { isAuthenticated: boolean }): ReactElement {
    const url = isAuthenticated ? '/' : 'https://lendo.se/';
    function onLogoClicked(): void {
        Analytics.pushUIInteraction({
            type: 'click',
            category: 'navigation',
            title: 'logo',
        });
    }
    return (
        <Link href={url}>
            <Box onClick={onLogoClicked}>
                <img src={`${externalRoutes.cdn}/img/logos/logo_white.svg`} alt="lendo logo" />
            </Box>
        </Link>
    );
}
function Hamburger(props: { open: boolean; onClick: () => void; [key: string]: unknown }): ReactElement {
    const { open, ...rest } = props;
    const hamburgerClassName = classNames(styles.hamburger, open && styles.open);

    return (
        <Box {...rest} className={styles.hamburgerButton}>
            <div className={hamburgerClassName}>
                <span></span>
                <span></span>
                <span></span>
            </div>
            <Text size="xxs" weight="medium" style={{ color: 'white' }}>
                {open ? 'STÄNG' : 'MENY'}
            </Text>
        </Box>
    );
}
