import { isProductionOrUnknown } from './environment';

export const externalRoutes = {
    cdn: 'https://se.cdn.lendocdn.com',
    lendoNo: 'https://www.lendo.no',
    lendoDk: 'https://www.lendo.dk',
    inbox: 'https://inbox.lendo.se',
    inboxStage: 'https://inbox.stage.lendo.se',
    landing: 'https://www.lendo.se',
    landingStage: 'https://stage.lendo.se',
};

export const getInboxUrlPerEnv = (): string =>
    isProductionOrUnknown() ? externalRoutes.inbox : externalRoutes.inboxStage;

export const getLandingUrlPerEnv = (): string =>
    isProductionOrUnknown() ? externalRoutes.landing : externalRoutes.landingStage;
