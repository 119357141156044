/**
 * Determines if the supplied date object is before the supplied diff object.
 *
 * @param dateObj {object} Object containing year, month and days.
 * @param diffObj {object} Object containing year, month and days.
 *
 * @returns {boolean} True if the supplied date is before the supplied diff, false if not.
 */

type DateObject = {
    year: number;
    month: number;
    days?: number;
};

export function IsBeforeValidation(dateObj: DateObject, diffObj: DateObject): boolean {
    const month = dateObj.month || 1;
    const year = dateObj.year || 1;
    const date = new Date(year, month - 1); // the month is 0-indexed

    const diffMonth = diffObj.month || 0;
    const diffYear = diffObj.year || 0;
    const diffDate = new Date();

    diffDate.setMonth(diffDate.getMonth() - diffMonth);
    diffDate.setFullYear(diffDate.getFullYear() - diffYear);

    return date < diffDate;
}

/**
 * Determines if the company is registered before the given number of months.
 *
 * @param companyCreatedDateObj {object} Object containing year, month and days.
 * @param numberOfMonths {Number} company must be older than this value.
 *
 * @returns {boolean} True if company is registered before the given number of months, false if not.
 */
export function CompanyRegistryDateValidation(companyCreatedDateObj: DateObject, numberOfMonths: number): boolean {
    const month = companyCreatedDateObj.month || 1;
    const year = companyCreatedDateObj.year || 1;
    const diffDate = new Date();

    const yearsInMonths = (diffDate.getFullYear() - year) * 12;
    const months = diffDate.getMonth() + 1 - month; // the month is 0-indexed

    const monthsSinceCompanyRegistry = yearsInMonths + months;

    return monthsSinceCompanyRegistry > numberOfMonths;
}

/**
 * Determines if the supplied date object is before the supplied diff object.
 *
 * @param dateObj {object} Object containing year, month and days.
 * @param diffObj {object} Object containing year, month and days.
 *
 * @returns {boolean} True if the supplied date is before the supplied diff, false if not.
 */
export function IsAfterValidation(dateObj: DateObject, diffObj: DateObject): boolean {
    const month = dateObj.month || 1;
    const year = dateObj.year || 1;
    const date = new Date(year, month);

    const diffMonth = diffObj.month || 0;
    const diffYear = diffObj.year || 0;
    const diffDate = new Date();

    diffDate.setMonth(diffDate.getMonth() + diffMonth);
    diffDate.setFullYear(diffDate.getFullYear() + diffYear);

    return date > diffDate;
}

/**
 * @param {Function} validator
 * @param {string} message
 */
export function withMessage(validator: unknown, message: string): unknown {
    if (typeof validator !== 'function') {
        throw new Error('Validator must be a function');
    }

    if (typeof message !== 'string') {
        throw new Error('Message must be of type string');
    }

    return (...args: unknown[]) => {
        const validationRes = validator(...args);

        if (typeof validationRes === 'boolean' && !validationRes) {
            return message;
        }

        return undefined;
    };
}

/**
 * Used for optional fields in the form
 * Checks if the value is empty and returns true, if value isn't empty then it performs the validator check
 * @param {Function} validator
 */

type Validator = (value: string) => boolean;
export function buildOptionalValidator(validator: Validator): (value: string) => boolean {
    return value => {
        if (typeof value === 'string' && !value) {
            return true;
        } else {
            return validator(value);
        }
    };
}

export function isNullOrUndefined(value: unknown): boolean {
    return value === null || value === undefined || value === '';
}

function notEmpty(value: string): boolean {
    return value !== undefined && value !== null && value !== '' && value.length !== 0;
}

export function minLength(min: number) {
    return (value: string): boolean => {
        return notEmpty(value) && value.length >= min;
    };
}

export function parseNumber(value: string): number {
    const parsed = Number(value);
    return parsed;
}

export function partiallyShowSuccessState(value: number | string): boolean {
    return typeof value === 'number' || value.length > 0;
}

export function isValidNumber(value: number): boolean {
    return !isNaN(value);
}
