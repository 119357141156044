import { useDecision } from '@optimizely/react-sdk';

import { getEnvironmentFromHost } from '../environment';
import { OptimizelyEnvironments } from './interfaces';
import { optimizelySDKKeys } from './optimizelyConfig';

const environment = getEnvironmentFromHost() as OptimizelyEnvironments;
export const sdkKey = optimizelySDKKeys[environment];

export const useIsExperiment = (decisionKey: string, variant: string): boolean => {
    const [decision] = useDecision(decisionKey);
    const isExperiment = decision?.enabled && decision.variationKey === variant;
    return isExperiment;
};

export const DECISIONS = {
    VARIANT_A: 'variant_a',
    VARIANT_B: 'variant_b',
};
