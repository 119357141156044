import { externalRoutes } from '@frontend/shared/utils';
import { Box, Column, Columns, EmailIcon, Inline, PhoneIcon, SectionBlock, Stack, Text } from '@lendoab/aphrodite';
import { Analytics } from 'APP/Analytics';
import { lendoDkLogo, lendoNoLogo, lendoSeLogo } from 'APP/config/logoSources';
import { CustomerSupportConstants } from 'APP/constants/CustomerSupportConstants';
import Link from 'next/link';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

import { PrivateUserAgreementModal } from '../Modals/UserAgreementModal';
import styles from './Footer.module.scss';

export function Footer(props) {
    const date = new Date();
    return (
        <Box {...props}>
            <SectionBlock backgroundColor="dark">
                <FooterList marginBottom="3xl" />

                <FooterDivider marginBottom="3xl" />

                <Box display="flex" flexDirection="column" alignItems={'center'}>
                    <FooterImages />

                    <Text marginTop="2xl" align="center" color="light" size="xs">
                        © {date.getFullYear()} Lendo AB / Org. nr:{' '}
                        <Text component="span" color="light" size="xs">
                            556515
                        </Text>
                        -
                        <Text component="span" color="light" size="xs">
                            1627
                        </Text>{' '}
                        / Västra Järnvägsgatan 21, 111 64 Stockholm
                    </Text>
                </Box>
            </SectionBlock>
        </Box>
    );
}

function FooterList(props) {
    return (
        <Fragment>
            <Box className={styles.columns} marginX={['none', 'auto', 'none']} {...props}>
                <Columns space="medium" collapseBelow="tablet">
                    <Column display={['block', 'none', 'block']}>
                        <Stack space="medium">
                            <FooterListHeader>Våra tjänster</FooterListHeader>

                            <FooterListItem component="a" href="https://www.lendo.se/privatlan">
                                Privatlån
                            </FooterListItem>

                            <FooterListItem component="a" href="https://www.lendo.se/samla-lan-krediter">
                                Samla lån
                            </FooterListItem>

                            <FooterListItem component="a" href="https://www.lendo.se/billan">
                                Billån
                            </FooterListItem>

                            <FooterListItem component="a" href="https://www.lendo.se/blancolan">
                                Blancolån
                            </FooterListItem>

                            <FooterListItem component="a" href="https://www.lendo.se/foretagslan">
                                Företagslån
                            </FooterListItem>

                            <FooterListItem component="a" href="https://www.lendo.se/batlan">
                                Båtlån
                            </FooterListItem>

                            <FooterListItem component="a" href="https://www.lendo.se/trygghetsforsakring">
                                Trygghetsförsäkring
                            </FooterListItem>
                        </Stack>
                    </Column>

                    <Column display={['block', 'none', 'block']}>
                        <Stack space="medium">
                            <FooterListHeader>Lär dig mer</FooterListHeader>

                            <FooterListItem component="a" href="https://www.lendo.se/allt-om-lan">
                                Allt om lån
                            </FooterListItem>
                            <FooterListItem component="a" href="https://www.lendo.se/lanebloggen">
                                Lånebloggen
                            </FooterListItem>
                        </Stack>
                    </Column>

                    <Column display={['block', 'none', 'block']}>
                        <Stack space="medium">
                            <FooterListHeader>Kontakt</FooterListHeader>

                            <Inline space="xs">
                                <PhoneIcon color="lightGray" />
                                <FooterListItem component="a" href={`tel:${CustomerSupportConstants.rawPhoneNumber}`}>
                                    {CustomerSupportConstants.prettyPhoneNumber}
                                </FooterListItem>
                            </Inline>

                            <Inline space="xs">
                                <EmailIcon color="lightGray" />
                                <FooterListItem component="a" href="mailto:kundservice@lendo.se">
                                    kundservice@lendo.se
                                </FooterListItem>
                            </Inline>

                            <FooterListItem component="a" href="https://www.lendo.se/kundtjanst">
                                Kundtjänst
                            </FooterListItem>

                            <FooterListItem
                                component="a"
                                href="https://www.lendo.se/privacy-policy/personuppgiftspolicy"
                            >
                                Personuppgiftspolicy
                            </FooterListItem>

                            <FooterListItem component="a" href="https://www.lendo.se/privacy-policy/cookies">
                                Cookies
                            </FooterListItem>

                            <Text
                                backgroundColor="transparent"
                                cursor="pointer"
                                padding="none"
                                borderWidth="none"
                                color="lightGray"
                                size="xs"
                                component="button"
                                onClick={() => window.__ucCmp?.showSecondLayer()}
                            >
                                Ändra val av cookies
                            </Text>
                        </Stack>
                    </Column>

                    <Column display={['block', 'none', 'block']}>
                        <Stack space="medium">
                            <Text color="light" weight="bold" size="medium">
                                Lendo
                            </Text>

                            <FooterListItem component="a" href="https://www.lendo.se/om-lendo">
                                Om Lendo
                            </FooterListItem>

                            <FooterListItem component="a" href="https://www.lendo.se/om-lendo/karriar">
                                Karriär
                            </FooterListItem>

                            <FooterListItem component="a" href="https://www.lendo.se/partners">
                                Våra partners
                            </FooterListItem>

                            <PrivateUserAgreementModal>
                                <FooterListItem component="button">Användaravtal</FooterListItem>
                            </PrivateUserAgreementModal>
                        </Stack>
                    </Column>

                    {/*TABLET*/}
                    <Column display={['none', 'block', 'none']} paddingLeft="none">
                        <Stack space="xl">
                            <FooterListHeader>Våra tjänster</FooterListHeader>

                            <FooterListItem component="a" href="https://www.lendo.se/privatlan">
                                Privatlån
                            </FooterListItem>

                            <FooterListItem component="a" href="https://www.lendo.se/samla-lan-krediter">
                                Samla lån
                            </FooterListItem>

                            <FooterListItem component="a" href="https://www.lendo.se/billan">
                                Billån
                            </FooterListItem>

                            <FooterListItem component="a" href="https://www.lendo.se/blancolan">
                                Blancolån
                            </FooterListItem>

                            <FooterListItem component="a" href="https://www.lendo.se/foretagslan">
                                Företagslån
                            </FooterListItem>

                            <FooterListItem component="a" href="https://www.lendo.se/batlan">
                                Båtlån
                            </FooterListItem>

                            <FooterListItem component="a" href="https://www.lendo.se/trygghetsforsakring">
                                Trygghetsförsäkring
                            </FooterListItem>

                            <FooterListHeader>Lär dig mer</FooterListHeader>

                            <FooterListItem component="a" href="https://www.lendo.se/allt-om-lan">
                                Allt om lån
                            </FooterListItem>
                            <FooterListItem component="a" href="https://www.lendo.se/lanebloggen">
                                Lånebloggen
                            </FooterListItem>
                        </Stack>
                    </Column>

                    <Column display={['none', 'block', 'none']}>
                        <Stack space="xl">
                            <FooterListHeader>Kontakt</FooterListHeader>

                            <Inline space="xs">
                                <PhoneIcon color="lightGray" />
                                <FooterListItem component="a" href={`tel:${CustomerSupportConstants.rawPhoneNumber}`}>
                                    {CustomerSupportConstants.prettyPhoneNumber}
                                </FooterListItem>
                            </Inline>

                            <Inline space="xs">
                                <EmailIcon color="lightGray" />
                                <FooterListItem component="a" href="mailto:kundservice@lendo.se">
                                    kundservice@lendo.se
                                </FooterListItem>
                            </Inline>

                            <FooterListItem component="a" href="https://www.lendo.se/kundtjanst">
                                Kundtjänst
                            </FooterListItem>

                            <FooterListItem
                                component="a"
                                href="https://www.lendo.se/privacy-policy/personuppgiftspolicy"
                            >
                                Personuppgiftspolicy
                            </FooterListItem>

                            <FooterListItem component="a" href="https://www.lendo.se/privacy-policy/cookies">
                                Cookies
                            </FooterListItem>

                            <Text
                                backgroundColor="transparent"
                                cursor="pointer"
                                padding="none"
                                borderWidth="none"
                                color="lightGray"
                                size="xs"
                                component="button"
                                onClick={() => window?.UC_UI?.showSecondLayer()}
                            >
                                Ändra val av cookies
                            </Text>

                            <Text color="light" weight="bold" size="medium">
                                Lendo
                            </Text>

                            <FooterListItem component="a" href="https://www.lendo.se/om-lendo">
                                Om Lendo
                            </FooterListItem>

                            <FooterListItem component="a" href="https://www.lendo.se/om-lendo/karriar">
                                Karriär
                            </FooterListItem>

                            <FooterListItem component="a" href="https://www.lendo.se/partners">
                                Våra partners
                            </FooterListItem>

                            <PrivateUserAgreementModal>
                                <FooterListItem component="button">Användaravtal</FooterListItem>
                            </PrivateUserAgreementModal>
                        </Stack>
                    </Column>
                </Columns>
            </Box>
        </Fragment>
    );
}

function FooterListHeader({ children, ...rest }) {
    return (
        <Text color="light" weight="bold" size="medium" {...rest}>
            {children}
        </Text>
    );
}

FooterListHeader.propTypes = {
    children: PropTypes.node.isRequired,
};

function FooterListItem({ children, component, href, ...rest }) {
    function onItemClicked() {
        Analytics.pushUIInteraction({
            type: 'click',
            category: 'footer',
            title: typeof children === 'string' ? children : 'Footer List Item',
        });
    }

    if (component === 'button') {
        return (
            <Text
                backgroundColor="transparent"
                cursor="pointer"
                padding="none"
                borderWidth="none"
                color="lightGray"
                size="xs"
                component={component}
                href={href}
                onClick={onItemClicked}
                {...rest}
            >
                {children}
            </Text>
        );
    }

    if (component === 'a') {
        return (
            <Text
                backgroundColor="transparent"
                cursor="pointer"
                padding="none"
                borderWidth="none"
                color="lightGray"
                size="xs"
                component={component}
                href={href}
                onClick={onItemClicked}
                {...rest}
            >
                {children}
            </Text>
        );
    }

    return (
        <Link href={href}>
            <Text
                backgroundColor="transparent"
                cursor="pointer"
                padding="none"
                borderWidth="none"
                color="lightGray"
                size="xs"
                onClick={onItemClicked}
                {...rest}
            >
                {children}
            </Text>
        </Link>
    );
}

FooterListItem.propTypes = {
    children: PropTypes.node.isRequired,
    component: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    href: PropTypes.string,
};

function FooterDivider(props) {
    return (
        <Box
            style={{ height: '1px', backgroundColor: '#9295A7' }}
            marginX={['none', 'auto', 'none']}
            fluid
            className={styles.columns}
            {...props}
        />
    );
}

function FooterImages() {
    return (
        <Columns className={styles.columns} space="2xl" collapseBelow="desktop">
            <Column display={['none', 'none', 'flex']}>
                <Box component="a" href="/">
                    <Box
                        loading="lazy"
                        component="img"
                        className={styles.footerLogo}
                        alt="lendo se"
                        src={lendoSeLogo}
                    />
                </Box>
            </Column>
            <Column display={['none', 'none', 'flex']}>
                <Box component="a" href={externalRoutes.lendoNo} rel="noreferrer" target="_blank">
                    <Box
                        loading="lazy"
                        component="img"
                        className={styles.footerLogo}
                        alt="lendo no"
                        src={lendoNoLogo}
                    />
                </Box>
            </Column>
            <Column display={['none', 'none', 'flex']}>
                <Box component="a" href={externalRoutes.lendoDk} rel="noreferrer" target="_blank">
                    <Box
                        loading="lazy"
                        component="img"
                        className={styles.footerLogo}
                        alt="lendo dk"
                        src={lendoDkLogo}
                    />
                </Box>
            </Column>

            <Columns space="2xl">
                <Column>
                    <Stack space="small" display={['flex', 'flex', 'none']}>
                        <Box component="a" href="/">
                            <Box
                                loading="lazy"
                                component="img"
                                className={styles.footerLogo}
                                alt="lendo se"
                                src={lendoSeLogo}
                            />
                        </Box>
                        <Box component="a" href={externalRoutes.lendoDk} rel="noreferrer" target="_blank">
                            <Box
                                loading="lazy"
                                component="img"
                                className={styles.footerLogo}
                                alt="lendo dk"
                                src={lendoDkLogo}
                            />
                        </Box>
                        <Box component="a" href={externalRoutes.lendoNo} rel="noreferrer" target="_blank">
                            <Box
                                loading="lazy"
                                component="img"
                                className={styles.footerLogo}
                                alt="lendo no"
                                src={lendoNoLogo}
                            />
                        </Box>
                    </Stack>
                </Column>
            </Columns>
        </Columns>
    );
}
