/* eslint-disable no-shadow */
import { isNullOrUndefined } from '../validators';

enum Environments {
    PRODUCTION = 'production',
    STAGE = 'stage',
    VERCEL = 'vercel',
    LOCALHOST = 'localhost',
    UNKNOWN = 'unknown',
}

export function getEnvironmentFromHost(): Environments {
    if (typeof window === 'undefined' || isNullOrUndefined(window.location.host)) {
        return Environments.UNKNOWN;
    }

    const host = window.location.host;

    if (host.includes('vercel.app')) {
        return Environments.VERCEL;
    } else if (host.includes('stage')) {
        return Environments.STAGE;
    } else if (host.includes('localhost')) {
        return Environments.LOCALHOST;
    } else if (host.includes('lendo.se')) {
        return Environments.PRODUCTION;
    }

    return Environments.UNKNOWN;
}

export const isProd = (): boolean => {
    const environment = getEnvironmentFromHost();
    return environment === Environments.PRODUCTION;
};

export const isProductionOrUnknown = (): boolean => {
    const environment = getEnvironmentFromHost();
    return environment === Environments.PRODUCTION || environment === Environments.UNKNOWN;
};
